/* You can add global styles to this file, and also import other style files */

html {
  height: 100%;
}

body {
  background-color: #f2f3f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #515f75;
  line-height: 120%;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 100%;
}

.container-fluid {
  min-height: 100%;
  /* padding-bottom: 20px; */
  /* isso causa scroll duplo no criador */
  padding-left: 0;
  padding-right: 0;
}

.page-criador .container-fluid {
  padding-bottom: 0;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.box-fullscreen-only {
  display: none;
}

/* HEADER */

.page-inicio .menu-bot,
.page-inicio .menu-principal {
  display: none;
}

.linha-menu {
  height: 58px;
}

.navbar-brand {
  padding: 0 !important;
}

.navbar-brand img {
  max-height: 55px;
  z-index: 40;
}

.page-criador .navbar-brand,
.page-canais .navbar-brand,
.page-analise .navbar-brand {
  height: 55px;
  width: 57px;
  background: #2f3845;
  z-index: 30;
}

.linha-menu .navbar {
  border-bottom: 2px solid #f49400;
  height: 57px;
  background-color: #3d4859;
  padding: 0;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.navbar .menu-topo {
  height: 55px;
  min-height: 55px;
  z-index: 20;
  width: 29.28%;
  max-width: 600px;
}

.navbar .menu-topo a:hover {
  text-decoration: none !important;
}

.menu-bot {
  display: flex;
  flex-wrap: wrap;
  z-index: 30;
  display: flex;
  align-items: center;
}

/*noinspection ALL*/

.page-dashboard .menu-bot span {
  display: block;
  background-image: url(/assets/images/menu-ativo.png);
  background-position: bottom;
  background-repeat: repeat-x;
  height: 65px !important;
  font-size: 22px;
  color: #ffffff;
  font-weight: 500;
  padding: 22px 10px 0 10px;
  margin-right: 2px;
}

.menu-bot span {
  display: block;
  height: 65px !important;
  font-size: 22px;
  color: #ffffff;
  font-weight: 500;
  padding: 22px 10px 0 10px;
  margin-right: 2px;
}

.menu-play {
  position: absolute;
  right: 125px;
  z-index: 30;
}

.texto-hover-title {
  position: absolute;
  margin-top: 50px;
  font-size: 11px;
  color: white;
  background-color: #515f75;
  border-radius: 3px;
  padding: 0px 6px;
  margin-left: 3px;
}
.seta-pra-cima {
  position: absolute;
  content: '';
  display: inline-block;
  width: 12px !important;
  height: 7px;
  border-right: 0.2em solid #515f75;
  border-top: 0.2em solid #515f75;
  transform: rotate(45deg);
  background-color: #515f75;
  margin-top: 40px;
  margin-left: 4px;
}
.nonne {
  display: none;
}
.seta-pra-cima-2 {
  position: absolute;
  content: '';
  display: inline-block;
  width: 12px !important;
  height: 7px;
  border-right: 0.2em solid #515f75;
  border-top: 0.2em solid #515f75;
  transform: rotate(-45deg);
  background-color: #515f75;
  margin-top: 40px;
  margin-left: 1px;
}

.menu-container {
  height: inherit;
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.menu-container a {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  color: transparent !important;
}

.menu-container ul {
  display: flex;
  padding: 0;
  width: 100%;
  justify-content: space-around;
}

.menu-container a {
  margin-top: 15px !important;
  border-radius: 50px;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-container ul li {
  height: 65px;
  font-size: 17px;
  position: relative;
  list-style: none;
}

.menu-container ul li .icon-menu {
  position: absolute;
  top: 15px;
  left: 40px;
}

.menu-container a:hover {
  border: 1px solid #707070;
  background-color: #2f3845;
  padding: 5px;
  color: transparent !important;
}

.menu-container a.menu-topo-ativo {
  border: 2px solid #f49423;
  background-color: #2f3845;
  padding: 5px;
  transition: 0.3s;
}

.menu-container a.menu-topo-ativo:hover {
  border: 2px solid rgba(244, 148, 35, 0.5);
}

#id-usuario {
  /*width: 100px;*/
  height: 55px;
  margin-top: -7px;
  background: #2f3845;
  z-index: 40;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-toggle {
  padding-right: 0rem;
}

.menu-open-user {
  padding-top: 30px;
  margin: 0 9px;
}

.usuario-imagem {
  width: 45px;
  height: 45px;
  margin-left: 10px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #ff9900;
}

.usuario-imagem img {
  width: 100%;
  height: auto;
}

.menu-ativo {
  /*noinspection CssUnknownTarget*/
  background-image: url(/assets/images/menu-ativo.png);
  background-position: bottom;
  background-repeat: repeat-x;
  height: 74px !important;
}

/* CONTEUDO */

.linha-conteudo {
  width: 100%;
}

.conteudo-iniciar {
  padding-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.box-iniciar {
  width: 215px;
  height: 250px;
  background: #ffffff;
  border-radius: 15px;
  margin: 0 15px;
  text-align: center;
  padding: 10px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  -moz-box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  margin-bottom: 20px;
}

.box-iniciar__tag {
  position: absolute;
  left: -1px;
  top: 16px;
  width: max-content;
  height: 22px;
  padding: 0 15px;
  border-radius: 0px 11px 11px 0px;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #fff;
}

.box-iniciar__tag--orange {
  background: #f49400;
}

.box-iniciar__tag--blue {
  background: #0d84ff;
}

.box-iniciar-avatar-imagem {
  width: 90px;
  height: 90px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.box-iniciar-avatar-imagem img {
  max-height: 90px;
  object-fit: cover;
}

.box-iniciar-titulo {
  margin-top: 28px;
  height: 47px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.box-iniciar-titulo p {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  width: 175px;
  overflow: hidden;
  display: inline-block;
}

.box-iniciar-subtitulo p {
  font-size: 15px;
  font-weight: 400;
  color: #a8b1bf;

  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}

/* DASHBOARD */

.barra-dashboard {
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 10px;
  height: 75px;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
}

.barra-dash-controle {
  display: flex;
  flex-wrap: wrap;
}

.barra-dash-avatar-status {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid;
  padding: 2px;
}

.dash-status-offline {
  border-color: #ed2f4f !important;
}

.dash-status-online {
  border-color: #15ce7e !important;
}

.barra-dash-avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.barra-dash-avatar-imagem img {
  max-height: 50px;
  object-fit: cover;
}

.barra-dash-controle-texto {
  padding-left: 10px;
  padding-top: 10px;
}

.barra-dash-controle-texto p {
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 500;
}

.barra-dash-controle-texto .custom-switch .custom-control-label {
  color: #a8b1bf;
  font-style: italic;
  font-size: 14px;
  padding-top: 4px;
}

.barra-dash-infos {
  text-align: right;
  font-size: 14px;
  color: #a8b1bf;
  padding-top: 10px;
}

.barra-dash-infos p {
  margin-bottom: 0;
}

.conteudo-dashboard {
  padding-top: 60px;
}

.conteudo-dashboard-status {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.box-dashboard {
  width: 300px;
  height: 250px;
  background: #ffffff;
  border-radius: 15px;
  padding: 20px 30px 0 30px;
  text-align: center;
  margin: 0 10px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  -moz-box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
}

.box-dash-icone {
  height: 120px;
  border-bottom: 2px solid #e5e5e5;
  margin-bottom: 25px;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 10px;
}

.box-dash-titulo p {
  font-size: 20px;
  margin-bottom: 15px;
}

.box-dash-counter {
  font-size: 22px;
  font-weight: 500;
}

.box-dashboard.box-dash-usuarios .box-dash-counter {
  color: #f49400;
}

.box-dashboard.box-dash-recebidas .box-dash-counter {
  color: #f15f52;
}

.box-dashboard.box-dash-enviadas .box-dash-counter {
  color: #15ce7e;
}

.conteudo-dashboard-canais {
  padding-bottom: 10px;
}

.box-canais {
  display: flex;
  background: rgba(43, 50, 61, 0.75);
  width: 940px;
  margin: 0 auto;
  border-radius: 15px;
  padding: 30px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
}

.box-canais-titulo p {
  color: #fff;
  margin-bottom: 0;
  padding-top: 10px;
}

.box-canais-canais {
  display: flex;
  justify-content: space-around;
}

/* CRIADOR */

.page-criador footer {
  display: none;
}

.menu-criador {
  position: absolute;
  left: 0;
  top: 54px;
  width: 57px;
  height: calc(100% - 54px);
  background: #2f3845;
  z-index: 10000;
}

.menu-criador .menu-criador-item {
  width: 48px;
  height: 43px;
  margin: 20px 0 10px 7px;
}

.menu-criador .menu-criador-item a {
  display: flex;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  justify-content: center;
}

.menu-criador .menu-criador-item a:hover {
  border: 2px solid #ffaa00;
}

/*noinspection ALL*/

.menu-criador .menu-criador-item.menu-criador-ativo {
  background-image: url(/assets/images/icons/dot_laranja.svg);
  background-repeat: no-repeat;
  background-position: right center;
}

.menu-criador .menu-criador-item.menu-criador-ativo a {
  background: #515b6a;
}

.menu-criador .menu-criador-item.menu-criador-ativo a:hover {
  border: 2px solid transparent;
}

.menu-criador-submenu {
  position: absolute;
  left: 57px;
  width: 245px;
  height: 276px;
  border-radius: 0 10px 10px 0;
  padding: 0px 0px 10px 0;
  background: rgba(47, 56, 69, 0.9);
  z-index: 100;
}

.menu-criador-submenu.submenu-blocos {
  top: 65px;
}

.submenu-voice-bot-blocos {
  height: 480px;
}

.submenu-voice-bot-blocos .creator-menu {
  height: max-content;
  overflow: hidden;
  height: 100%;
}

.menu-criador-submenu-itens {
  max-height: 290px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}

.criador-sub-item {
  position: relative;
  width: 50%;
  height: 60px;
  color: #fff;
  text-align: center;
  font-size: 11px;
  margin-top: 12px;
}

.criador-sub-item img {
  position: relative;
  top: 30%;
  transform: translateY(-50%);
}

.criador-sub-item p {
  position: absolute;
  width: 100%;
  margin-bottom: 0;
  bottom: 0;
}

.menu-criador-submenu-select .form-group {
  margin-bottom: 0;
}

/*noinspection ALL*/

.menu-criador-submenu-select .form-control {
  -webkit-appearance: none;
  padding: 0 0 4px 12px;
  height: 30px;
  font-size: 16px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  border-radius: 0;
  background-image: url(/assets/images/select_laranja.svg);
  background-repeat: no-repeat;
  background-position: 95% 45%;
  background-size: 12px;
}

.menu-criador-submenu-select:not(:first-child) .form-control {
  border-top: 1px solid #2f3845;
  margin-top: 10px;
  padding-top: 10px;
  background-position: 95% 70%;
}

.conteudo-criador {
  width: 100%;
  height: calc(100vh - 58px);
  padding-left: 57px;
  padding-right: 0;
  position: relative;
}

.menu-bot-criador {
  position: absolute;
  width: 250px;
  top: 20px;
  right: 30px;
}

.menu-criador-botoes {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  padding: 0 10px;
}

.menu-criador-botoes button {
  -webkit-appearance: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: #dce1e6;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(157, 181, 203, 0.25);
  -moz-box-shadow: 0px 2px 5px 0px rgba(157, 181, 203, 0.25);
  box-shadow: 0px 2px 5px 0px rgba(157, 181, 203, 0.25);
}

/*noinspection ALL*/

.menu-criador-botoes button.bt-bot-on {
  background: #515f75;
  background-image: url(/assets/images/icons/dot_laranja.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
}

.menu-criador-botoes button:hover {
  background: #515f75;
}

.menu-criador-box button.bt-pill {
  -webkit-appearance: none;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #515f75;
  color: #515f75;
  font-size: 14px;
  margin-right: 2px;
  margin-top: 4px;
}

.menu-criador-box .custom-switch .custom-control-label::after {
  background-color: #ffffff;
}

.menu-criador-box
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #0d84ff;
  background-color: #0d84ff;
}

.menu-criador-box .criador-box-bt button {
  -webkit-appearance: none;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: none;
  background: #f15f52;
  font-size: 13px;
  color: #ffffff;
}

.menu-criador-box .criador-box-bt-img {
  margin-right: 10px;
}

.menu-criador-box {
  background: #ffffff;
  border-radius: 15px;
  border: 1px solid #f3f3f3;
  padding: 10px 5px 0 0;
  -webkit-box-shadow: 0px 6px 15px 0px rgba(157, 181, 203, 0.5);
  -moz-box-shadow: 0px 6px 15px 0px rgba(157, 181, 203, 0.5);
  box-shadow: 0px 6px 15px 0px rgba(157, 181, 203, 0.5);
  /* max-height: calc(100vh - 240px); */
}

.menu-criador-box-conteudo {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 230px);
}

.menu-criador-box-conteudo h3 {
  font-size: 17px;
  padding: 6px 0 0 10px;
}

.menu-criador-box-conteudo p {
  margin-bottom: 0;
}

.menu-criador-box-conteudo label {
  font-size: 14px;
  margin-right: 5px;
  padding-top: 5px;
  margin-bottom: 0;
}

.menu-criador-box-conteudo .custom-switch label {
  padding-top: 2px;
}

.menu-criador-box-conteudo .criador-box-id {
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #dce1e6;
}

.menu-criador-box-conteudo .criador-box-id .form-group {
  display: flex;
  margin-bottom: 0;
}

.menu-criador-box-conteudo .criador-box-id .form-control {
  height: 30px;
  border: none;
  background: #e9ebee;
  border-radius: 5px 0 0 5px;
  width: calc(100% - 30px);
}

.menu-criador-box-conteudo .criador-box-id-icone {
  height: 30px;
  width: 30px !important;
  background: #cacfd5;
  border-radius: 0 5px 5px 0;
  text-align: center;
  padding-top: 5px;
}

.menu-criador-box-conteudo .criador-box-resposta {
  padding: 10px;
  border-bottom: 1px solid #dce1e6;
}

.menu-criador-box-conteudo .criador-box-tempo {
  padding: 10px;
  border-bottom: 1px solid #dce1e6;
  display: flex;
}

.menu-criador-box-conteudo .criador-box-tempo .form-control {
  height: 30px;
  border: 1px solid #cacfd5;
  background: #dce1e6;
  border-radius: 5px;
  width: 30px;
  padding: 0;
}

.menu-criador-box-conteudo .criador-box-tempo p {
  font-size: 13px;
  color: #a1a8b0;
  padding-top: 5px;
  margin-left: 4px;
}

.menu-criador-box-conteudo .criador-box-cms {
  padding: 10px;
  border-bottom: 1px solid #dce1e6;
}

.menu-criador-box-conteudo .criador-box-cms,
.menu-criador-box-conteudo .criador-box-texto,
.menu-criador-box-conteudo .criador-box-imagem {
  padding: 10px;
  border-bottom: 1px solid #dce1e6;
}
.question-box {
  padding: 0px 0px !important;
}
.criador-box-texto .bot-texto-balao {
  background: #e9ebee;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
  position: relative;
  text-align: right;
}

.criador-box-texto .bot-texto-balao p {
  text-align: left !important;
}

.menu-criador-box .criador-box-bt {
  padding: 10px;
}

.menu-criador-box-conteudo .criador-box-form {
  padding: 10px;
}

.menu-criador-box-conteudo .criador-box-form .form-control {
  height: 30px;
  font-size: 14px;
  color: #495057;
  background-color: #e9ebee;
  border: none;
  border-radius: 5px;
}

/*noinspection ALL*/

.menu-criador-box-conteudo .criador-box-form .form-group {
  margin-bottom: 15px;
  margin-top: 5px;
}

.menu-criador-box-conteudo .criador-box-form p {
  font-size: 14px;
}

/*noinspection ALL*/

.criador-menu-bot-select .form-control {
  -webkit-appearance: none;
  padding: 2px 0 4px 12px;
  width: 100%;
  height: 30px;
  font-size: 14px;
  color: #8a96a8;
  background-color: #e9ebee;
  border: none;
  border-radius: 5px;
  background-image: url(/assets/images/icons/seta_drop_data.svg);
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-size: 10px;
}

.menu-criador-box-conteudo img.seta-expander {
  margin-left: 2px;
  margin-top: -2px;
}

.menu-criador-box .custom-control-label::before {
  background-color: #bbc3cc;
}

/* ANALISE */

.barra-analise {
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 10px;
  height: 75px;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
}

.barra-analise-controle {
  display: flex;
  flex-wrap: wrap;
}

.barra-analise-avatar-status {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid;
  padding: 2px;
}

.barra-analise-avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.barra-analise-avatar-imagem img {
  max-height: 50px;
  object-fit: cover;
}

.barra-analise-controle-texto {
  padding-left: 10px;
  padding-top: 10px;
}

.barra-analise-controle-texto p {
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 500;
}

.barra-analise-controle-texto .label-status p {
  color: #a8b1bf;
  font-style: italic;
  font-size: 14px;
  padding-top: 4px;
}

/*noinspection ALL*/

.barra-analise-header {
  background-image: url(/assets/images/menu-ativo.png);
  background-position: bottom;
  background-repeat: repeat-x;
}

.barra-analise-header p {
  float: left;
  font-size: 21px;
  font-weight: 500;
  padding-top: 16px;
}

.barra-analise-header img {
  float: right;
  margin-top: 10px;
  margin-left: 10px;
}

.barra-analise-infos {
  text-align: right;
  font-size: 14px;
  color: #a8b1bf;
  padding-top: 10px;
}

.barra-analise-infos p {
  margin-bottom: 0;
}

.conteudo-analise {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
}

.conteudo-analise-menu {
  width: calc(50% - 460px);
}

.analise-menu-box {
  width: 90%;
  max-width: 230px;
  min-height: 415px;
  background: #fff;
  border-radius: 15px;
  margin: 70px auto 0 auto;
  padding-top: 25px;
}

.analise-menu-box ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.analise-menu-box ul li {
  min-height: 35px;
  padding: 9px 0 0 13px;
  font-size: 16px;
  border-left: 2px solid transparent;
  border-bottom: 1px solid #cacbcc;
}

.analise-menu-box ul li a {
  color: #515f75;
}

.analise-menu-box ul li:hover {
  border-left: 2px solid #515f75;
}

.analise-menu-box ul li a:hover {
  text-decoration: none;
}

.analise-menu-box ul li:last-child {
  border-bottom: none;
}

.analise-menu-active {
  border-left: 2px solid #f68003 !important;
  font-weight: 500;
}

.conteudo-analise-info {
  width: 920px;
}

.analise-info-filtro {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 70px;
  padding-top: 25px;
}

.analise-filtro-select .form-group {
  margin-bottom: 0;
}

/*noinspection ALL*/

.analise-filtro-select .form-control {
  -webkit-appearance: none;
  padding: 0 0 4px 12px;
  width: 150px;
  height: 30px;
  font-size: 14px;
  color: #8a96a8;
  background-color: #e9ebee;
  border: none;
  border-radius: 15px;
  background-image: url(/assets/images/icons/seta_drop_data.svg);
  background-repeat: no-repeat;
  background-position: 92% 50%;
  background-size: 10px;
}

.analise-filtro-data .form-group {
  display: flex;
  margin-bottom: 0;
  width: 150px;
}

.analise-filtro-data .form-control {
  height: 30px;
  font-size: 14px;
  color: #8a96a8;
  background-color: #e9ebee;
  border: none;
  border-radius: 15px;
  margin-right: 5px;
}

.analise-info-filtro p {
  margin-bottom: 0;
  padding: 4px 8px 0 8px;
}

.analise-filtro-data label {
  margin-bottom: 0;
  font-size: 13px;
  padding: 5px 5px 0 0;
}

.analise-info-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.analise-info-stats-box {
  background: #ffffff;
  border-radius: 15px;
  padding: 15px;
  min-height: 150px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  -moz-box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
}

.analise-info-stats-box.analise-box-usuarios {
  width: 300px;
}

.analise-info-stats-box.analise-box-mensagens {
  width: 600px;
}

.analise-info-stats-box .analise-info-stats-box-titulo {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.analise-info-stats-box.analise-box-usuarios
  .analise-info-stats-box-titulo
  img {
  margin-top: -2px;
}

.analise-info-stats-box .analise-info-stats-box-titulo p {
  margin-bottom: 0;
  font-size: 20px;
  margin-left: 8px;
  color: #515f75;
}

.analise-info-stats-box .analise-info-stats-box-dados {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
  color: #8e97a4;
}

.analise-info-stats-box .stats-dados {
  min-height: 80px;
  padding: 18px 0 0 0;
  border-right: 2px solid #e9ebee;
}

.analise-info-stats-box .stats-dados:last-child {
  border-right: none;
}

.analise-info-stats-box .analise-dados-counter {
  font-size: 21px;
  color: #f49400;
  margin-bottom: 6px;
}

.analise-info-grafico {
  margin-bottom: 20px;
}

.analise-grafico-final {
  padding-bottom: 60px;
}

.analise-info-grafico-box {
  background: #ffffff;
  border-radius: 15px;
  padding: 15px;
  min-height: 380px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  -moz-box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
}

.analise-info-grafico-box .analise-info-grafico-box-titulo {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.analise-info-grafico-box .analise-info-grafico-box-titulo img {
  height: 20px;
  width: auto;
}

.analise-info-grafico-box .analise-info-grafico-box-titulo p {
  margin-bottom: 0;
  font-size: 20px;
  margin-left: 8px;
  color: #515f75;
}

.analise-info-filtro.filtro-monitoramento {
  justify-content: space-between;
}

.monitoramento-filtro-select {
  display: flex;
  flex-wrap: wrap;
}

.monitoramento-atendentes {
  display: flex;
  justify-content: space-around;
  width: 300px;
  height: 30px;
  border-radius: 15px;
  background: #3d4859;
  color: #ffffff;
  font-weight: 300;
  font-size: 14px;
  padding: 2px 8px 0 0;
}

.monitoramento-atendentes p {
  margin-bottom: 0;
}

.monitoramento-atendentes .status-atendente {
  display: flex;
  font-weight: 400;
  color: #ffffff;
  float: right;
  margin-left: 10px;
  padding: 4px 0 0 0;
}

.monitoramento-atendentes .status-atendente span {
  display: block;
  width: 10px;
  height: 10px;
  background: #6d7686;
  border-radius: 5px;
  margin-right: 4px;
  margin-top: 4px;
}

.monitoramento-atendentes .status-atendente.status-atend-online span {
  background: #15ce7e;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(21, 206, 115, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(21, 206, 115, 1);
  box-shadow: 0px 0px 5px 0px rgba(21, 206, 115, 1);
}

.monitoramento-atendentes .status-atendente.status-atend-pausa span {
  background: #ffaa00;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 170, 0, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(255, 170, 0, 1);
  box-shadow: 0px 0px 5px 0px rgba(255, 170, 0, 1);
}

.monitoramento-atendentes .status-atendente.status-atend-offline span {
  background: #ed2f4f;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(237, 47, 79, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(237, 47, 79, 1);
  box-shadow: 0px 0px 5px 0px rgba(237, 47, 79, 1);
}

.analise-info-tickets {
  margin-bottom: 20px;
}

.analise-info-tickets .analise-info-stats-box .stats-dados {
  border-right: none;
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}

.analise-info-tickets .analise-info-stats-box .stats-dados:nth-child(2) {
  border-right: 2px solid #e9ebee;
}

.analise-info-tickets .analise-info-stats-box .analise-dados-counter {
  margin-bottom: 16px;
}

.analise-info-tickets .analise-info-stats-box .analise-info-stats-box-dados {
  font-size: 14px;
}

.monitor-fila .analise-dados-counter {
  color: #0d84ff;
}

.monitor-atendimento .analise-dados-counter {
  color: #15ce7e;
}

.monitor-tickets .analise-dados-counter {
  color: #515f75;
}

.monitor-tempo .analise-dados-counter {
  color: #515f75;
}

.monitor-ok .analise-dados-counter {
  color: #515f75;
}

.monitor-lost .analise-dados-counter {
  color: #f15f52;
}

.analise-info-hoje {
  margin-bottom: 20px;
}

.analise-info-hoje .analise-info-stats-box .stats-dados {
  border-right: none;
  width: 14%;
  padding-left: 4px;
  padding-right: 4px;
}

.analise-info-hoje .analise-info-stats-box .analise-dados-counter {
  margin-bottom: 16px;
}

.analise-info-hoje .analise-info-stats-box .analise-info-stats-box-dados {
  font-size: 14px;
}

.analise-info-hoje .analise-info-stats-box .stats-dados:nth-child(3) {
  border-right: 2px solid #e9ebee;
}

.analise-tabela-box-nav {
  margin-bottom: 32px;
  margin-top: 12px;
}

.analise-tabela-box-nav .nav-tabela {
  font-size: 20px;
  color: #515f75;
  padding: 6px 16px;
  border-radius: 20px;
  border: 2px solid transparent;
  margin-right: 10px;
}

.analise-tabela-box-nav .nav-tabela.nav-tabela-ativo {
  border: 2px solid #ffaa00;
}

.analise-tabela-box-nav .nav-tabela:hover {
  border: 2px solid #515f75;
  text-decoration: none;
}

.analise-info-tabela-box {
  background: #ffffff;
  border-radius: 15px;
  padding: 15px;
  min-height: 380px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  -moz-box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
  box-shadow: 0px 4px 6px 0px rgba(157, 181, 203, 0.25);
}

.analise-tabela-header {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 15px);
}

.analise-tabela-header p {
  font-size: 16px;
  font-weight: 500;
  width: 15%;
}

.analise-tabela-header p:nth-child(2) {
  width: 17%;
}

.analise-tabela-header p:nth-child(3),
.analise-tabela-header p:nth-child(4) {
  width: 19%;
}

.analise-tabela-conteudo {
  max-height: 250px;
  overflow-y: scroll;
  padding-top: 10px;
  border-top: 2px solid #e9ebee;
}

.analise-tabela-conteudo table {
  width: calc(100% - 10px);
}

.analise-tabela-conteudo .tabela-linha {
  height: 27px;
}

.analise-tabela-conteudo .tabela-linha:nth-child(odd) {
  background: #edeff2;
}

.analise-tabela-conteudo .tabela-linha td {
  font-size: 14px;
  font-weight: 400;
  color: #8e97a4;
  width: 15%;
}

.analise-tabela-conteudo .tabela-linha td:first-child {
  padding-left: 10px;
}

.analise-tabela-conteudo .tabela-linha td:nth-child(2) {
  width: 17%;
}

.analise-tabela-conteudo .tabela-linha td:nth-child(3),
.analise-tabela-conteudo .tabela-linha td:nth-child(4) {
  width: 19%;
}

/* FORMULARIOS */

.box-iniciar .post-action select {
  -webkit-appearance: none;
  color: #949494;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  border: none;
  margin-bottom: 0 !important;
  width: auto;
  padding-right: 0;
}

.box-iniciar .form-group {
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
}

.barra-dashboard .custom-control-label::before {
  background-color: #e91e63;
  border: #fff solid 1px;
}

.barra-dashboard .custom-switch .custom-control-label::after {
  background-color: #ffffff;
}

.barra-dashboard .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #009688;
  background-color: #009688;
}

/* FOOTER */

footer {
  position: absolute;
  text-align: center;
  height: 60px;
  padding-top: 20px;
  width: 100%;
  bottom: 0;
  pointer-events: none;
}

/* SCROLL */

#style-submenu::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #303946;
}

#style-submenu::-webkit-scrollbar {
  width: 5px;
  background-color: #303946;
}

#style-submenu::-webkit-scrollbar-thumb {
  background-color: #77808e;
  border: none;
}

#style-submenu {
  scrollbar-color: #77808e #303946;
  scrollbar-width: thin;
}

#style-tabela::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #e9ebee;
}

#style-tabela::-webkit-scrollbar {
  width: 5px;
  background-color: #e9ebee;
}

#style-tabela::-webkit-scrollbar-thumb {
  background-color: #f49400;
  border: none;
}

#style-tabela {
  scrollbar-color: #f49400 #e9ebee;
  scrollbar-width: thin;
}

#style-criador::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #e9ebee;
}

#style-criador::-webkit-scrollbar {
  width: 5px;
  background-color: #e9ebee;
}

#style-criador::-webkit-scrollbar-thumb {
  background-color: #b0b7c0;
  border: none;
}

#style-criador {
  scrollbar-color: #b0b7c0 #e9ebee;
  scrollbar-width: thin;
}

/* COMMON */

:focus {
  outline: none !important;
}

.outline-none:focus {
  outline: 0 none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pointer {
  cursor: pointer;
}

.drop-zone {
  border: 0;
}

.page-wrap {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -60px;
}

.page-wrap:after {
  content: '';
  display: block;
}

.site-footer,
.page-wrap:after {
  height: 60px;
}

/* FULLSCREEN */

body.page-fullscreen {
  background-color: #2f3845;
  color: #ffffff;
}

.page-fullscreen .box-fullscreen-only {
  display: block;
}

.page-fullscreen .conteudo-analise-menu,
.page-fullscreen .menu-bot,
.page-fullscreen .navbar .menu-topo,
.page-fullscreen #id-usuario,
.page-fullscreen .barra-analise-header,
.page-fullscreen .barra-analise-infos,
.page-fullscreen .box-fullscreen-hide {
  display: none;
}

.page-fullscreen .linha-menu {
  height: 75px;
}

.page-fullscreen .conteudo-analise-info {
  margin: 0 auto;
}

.page-fullscreen .page-analise .navbar-brand {
  background: transparent;
}

.page-fullscreen .linha-menu .navbar {
  border-bottom: none;
  height: 75px;
  background-color: rgba(47, 56, 69, 0.8);
}

.page-fullscreen .barra-analise {
  padding-left: 0;
  padding-right: 60px;
  padding-top: 10px;
  width: calc(100% - 60px);
  height: 65px;
  background: transparent;
  position: fixed;
  top: 0;
  left: 60px;
  z-index: 2000;
}

.page-fullscreen .navbar-brand {
  z-index: 3000 !important;
}

.page-fullscreen .analise-info-filtro {
  height: 60px;
  padding-top: 10px;
  position: relative;
}

.page-fullscreen .monitoramento-atendentes {
  background: #515b6a;
}

/*noinspection ALL*/

.page-fullscreen .analise-filtro-select .form-control {
  color: #a8b1bf;
  background-color: #515b6a;
  background-image: url(/assets/images/icons/seta_drop_data_w.svg);
}

.page-fullscreen .analise-info-stats-box,
.page-fullscreen .analise-info-tabela-box {
  background: #3d4859;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.page-fullscreen .analise-info-grafico-box {
  background: #3d4859;
  min-height: 200px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.page-fullscreen .analise-info-stats-box .analise-info-stats-box-titulo p,
.page-fullscreen .analise-info-grafico-box .analise-info-grafico-box-titulo p {
  color: #ffffff;
}

.page-fullscreen .analise-info-stats-box .analise-info-stats-box-dados {
  color: #aab5c4;
}

.page-fullscreen .monitor-tickets .analise-dados-counter,
.page-fullscreen .monitor-tempo .analise-dados-counter,
.page-fullscreen .monitor-ok .analise-dados-counter {
  color: #ffffff;
}

.page-fullscreen
  .analise-info-hoje
  .analise-info-stats-box
  .stats-dados:nth-child(3) {
  border-right: 2px solid #aab5c4;
}

.page-fullscreen
  .analise-info-tickets
  .analise-info-stats-box
  .stats-dados:nth-child(2) {
  border-right: 2px solid #aab5c4;
}

.page-fullscreen .analise-tabela-box-nav .nav-tabela {
  color: #ffffff;
}

.page-fullscreen .analise-tabela-conteudo .tabela-linha:nth-child(odd) {
  background: #4e5c73;
}

.page-fullscreen .analise-tabela-header p {
  color: #a9b5c3;
}

.page-fullscreen .analise-tabela-conteudo .tabela-linha td {
  color: #bfc7d2;
}

.page-fullscreen .analise-tabela-conteudo {
  border-top: 2px solid #4e5c73;
}

.page-fullscreen #style-tabela::-webkit-scrollbar-track {
  background-color: #2f3845;
}

.page-fullscreen #style-tabela::-webkit-scrollbar {
  background-color: #2f3845;
}

.box-fullscreen-only.icone-action-fullscreen-close {
  position: absolute;
  right: 310px;
}

.box-fullscreen-only.icone-action-refresh {
  position: absolute;
  right: 350px;
}

.page-fullscreen .analise-grafico-final {
  padding-bottom: 30px;
}

.page-fullscreen footer {
  padding-top: 10px;
  font-size: 13px;
  color: #768398;
}

.hidden {
  display: none;
}

@media screen and (max-width: 1480px) {
  .analise-menu-box {
    width: 80%;
  }
  .analise-menu-box ul li {
    padding: 9px 9px 8px 13px;
  }
}

@media screen and (max-width: 1300px) {
  .analise-menu-box {
    width: 80%;
  }
  .barra-analise,
  .barra-dashboard {
    padding-left: 120px;
    padding-right: 120px;
  }
  .conteudo-analise-menu {
    width: calc(50% - 400px);
  }
  .conteudo-analise-info {
    width: 800px;
  }
  .analise-info-stats-box.analise-box-usuarios {
    width: 240px;
  }
  .analise-info-stats-box.analise-box-mensagens {
    width: 540px;
  }
  .analise-tabela-header p {
    font-size: 14px;
  }
  .analise-info-hoje .analise-info-stats-box .analise-info-stats-box-dados {
    font-size: 12px;
  }
  .analise-info-tickets .analise-info-stats-box .analise-info-stats-box-dados {
    font-size: 12px;
  }
}

@media screen and (max-width: 1080px) {
  .analise-menu-box {
    width: 85%;
  }
  .barra-analise,
  .barra-dashboard {
    padding-left: 15px;
    padding-right: 15px;
  }
  .conteudo-analise-menu {
    width: calc(50% - 280px);
  }
  .conteudo-analise-info {
    width: 760px;
  }
  .analise-info-stats-box.analise-box-usuarios {
    width: 230px;
  }
  .analise-info-stats-box.analise-box-mensagens {
    width: 520px;
  }
}

/* BOOTSTRAP CUSTOMIZATIONS */

input {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.btn {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  outline: none !important;
}

.btn:focus {
  outline: none !important;
}

.btn-primary {
  background-color: #f59500;
  border-color: #f59500;
  color: #fff;
}

.btn-primary:hover {
  background-color: #e28300 !important;
  border-color: #e28300 !important;
}

.creator-menu .header {
  -webkit-appearance: none;
  padding: 0 0 4px 12px;
  /*height: 30px;*/
  font-size: 16px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  border-radius: 0;
  background-image: url(/assets/images/select_laranja.svg);
  background-repeat: no-repeat;
  background-position: 95% 26%;
  background-size: 12px;
}

.creator-menu .header.collapsed {
  background-image: url('/assets/images/select_laranja left.png');
  background-size: 7px;
  background-position: 94% 25%;
}

ul.creator-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 276px;
  overflow: auto;
  margin-right: 5px;
}
.creator-menu-integracao {
  height: calc(100% - 98px) !important;
}
.creator-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.creator-menu input[type='radio'] {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.creator-menu li:not(.header) {
  position: relative;
  width: 30%;
  height: 67px;
  color: #fff;
  text-align: center;
  font-size: 11px;
  margin-top: 12px;
}

/*.creator-menu img {*/

/*  position: relative;*/

/*  top: 30%;*/

/*  transform: translateY(-50%);*/

/*}*/

.creator-menu label {
  position: absolute;
  width: 82px;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  padding: 5px;
  border-radius: 4px;
  height: max-content;
}

.dragging-overlay {
  background-color: #2f3845;
  position: absolute;
  width: 82px;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  padding: 5px;
  border-radius: 4px;
  z-index: 999;
  pointer-events: none;
}

.dragging {
  background-color: #2f3845;
  opacity: 0.5;
}

.creator-menu label img.no-drag {
  pointer-events: none;
  margin-top: 7px;
}

.creator-menu p {
  margin: 0 !important;
  line-height: 14px;
  margin-top: 4px !important;
}

.creator-menu input:checked + label {
  background: #2f3845;
  border-radius: 3px;
}

.creator-menu input:checked + label .hint {
  display: block !important;
}

.creator-menu .header.border-top {
  border-top: 1px solid #2f3845 !important;
  margin-top: 10px;
  padding-top: 10px;
  background-position: 95% 70%;
}

.creator-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #303946;
}

.creator-menu::-webkit-scrollbar {
  width: 5px;
  background-color: #303946;
}

.creator-menu::-webkit-scrollbar-thumb {
  background-color: #77808e;
  border: none;
}

.creator-menu {
  scrollbar-color: #77808e #303946;
  scrollbar-width: thin;
}

.creator-menu .hint {
  position: absolute;
  right: 1px;
  top: 5px;
  display: none;
}

.tooltip-inner {
  background: #2b323d 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 6px #00000040;
  opacity: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  font-size: 11px;
  text-align: center;
  font: Regular 11px/18px Fira Sans;
  letter-spacing: 0;
  color: #ffffff;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: rgba(32, 37, 44, 0.6) !important;
}

.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: rgba(32, 37, 44, 0.6) !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome, Opera and Firefox */
}

/*.dragging > * {*/

/*  display: none;*/

/*}*/

/* width */

textarea::-webkit-scrollbar {
  width: 3px;
  border-radius: 2px;
}

/* Track */

textarea::-webkit-scrollbar-track {
  background: #a8b1bf;
}

/* Handle */

textarea::-webkit-scrollbar-thumb {
  background: #77808e;
  border-radius: 2px;
}

/* Handle on hover */

textarea::-webkit-scrollbar-thumb:hover {
  background: #77808e;
}

textarea {
  scrollbar-color: #a8b1bf;
  scrollbar-width: thin;
}

.header-menu-criador-submenu-blocos {
  display: flex;
  align-items: center;
}

.select-bloco-background {
  background-color: #373b43;
}
.container-header-menu-blocos {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-border-top-right-radius: 14px;
  -moz-border-radius-topright: 14px;
  border-top-right-radius: 14px;
}

.sub-title-integracao {
  font-size: 16px;
  color: white;
  padding-left: 12px;
}

.header-integracao {
  background-position: 38% 47% !important;
  background-size: 9px !important;
}

.header-integracao.collapsed {
  background-size: 5px !important;
  background-position: 38% 47% !important;
}

.img-item-title-menu {
  transform: rotate(-90deg);
  width: 21px !important;
  margin-top: -10px;
}

.img-item-integracao {
  min-width: 18px !important;
  min-height: 18px !important;
  max-width: 18px !important;
  max-height: 18px !important;
  margin: 0px 0px 2px 0px;
}

.div-item-integracao {
  min-width: 20px !important;
  min-height: 20px !important;
  max-width: 20px !important;
  max-height: 20px !important;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ffaa00;
  opacity: 1;
}

.div-item-texto {
  margin-left: 10px;
}

.creator-menu-integracao .creator-menu .header {
  background-position: right !important;
  margin-right: 4px;
  cursor: pointer;
}
